import React from 'react';
import moment from 'moment';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/seo';

class PhotoPermalinkTemplate extends React.Component {
    render() {
        const { data } = this.props;
        const img = data.allFile.edges[0].node;
        const parts = img.base.split('__');
        const imgDate = moment.unix(parts[0]).format('MMMM D, YYYY');
        const imgTitle = parts[1].split('.')[0];
        const imgCaption = parts.length > 2 ? parts[2].split('.jpg')[0] : '';
        const meta = [
            {
                property: 'og:image',
                content: `https://rowanoulton.com${img.childImageSharp.fluid.src}`,
            },
            {
                property: 'twitter:label1',
                content: 'Photographed on',
            },
            {
                property: 'twitter:data1',
                content: imgDate,
            },
        ];

        return (
            <Layout>
                <SEO htmlClass="photolog" meta={meta} title={imgTitle} />
                <article className="img-wrap" key={img.id}>
                    <Img title={imgTitle} fluid={img.childImageSharp.fluid} />
                    <div className="img-details">
                        {imgCaption && <p className="img-caption">{imgCaption}</p>}
                        <p className="img-title">{imgTitle}</p>
                        <p className="img-date">{imgDate}</p>
                        <p className="img-back-btn">
                            <Link to="/photography">
                                <span className="arrow">←</span>&nbsp;&nbsp;More photographs
                            </Link>
                        </p>
                    </div>
                </article>
            </Layout>
        );
    }

    componentWillMount() {
        if (typeof window === 'undefined') return;
        const img = this.props.data.allFile.edges[0].node;
        document.documentElement.style.setProperty('--background', img.fields.averageColor);
        document.documentElement.style.setProperty('--foreground', img.fields.offsetColor);
    }

    componentWillUnmount() {
        if (typeof window === 'undefined') return;
        document.documentElement.style.setProperty('--background', null);
        document.documentElement.style.setProperty('--foreground', null);
    }
}

export default PhotoPermalinkTemplate;

export const pageQuery = graphql`
    query PhotoById($photo_id: String!) {
        allFile(filter: { id: { eq: $photo_id } }) {
            edges {
                node {
                    id
                    base
                    fields {
                        averageColor
                        offsetColor
                    }
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
